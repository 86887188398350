export default {
  namespaced: true,

  state: {
    market: null,
    language: null,
    isInitialized: false,
  },

  mutations: {
    SET_INITIAL_STATE(state) {
      state.isInitialized = true;
    },

    SET_MARKETS() {},

    SET_LANGUAGES() {},

    SET_LANGUAGE(state, language) {
      state.language = language;
    },

    SET_MARKET(state, market) {
      state.market = market;
    },
  },

  actions: {
    init({ commit }) {
      commit('SET_INITIAL_STATE');
    },
  },

  getters: {
    market(state) {
      return state.market;
    },

    language(state) {
      return state.language;
    },

    isInitialized(state) {
      return state.isInitialized;
    },
  },
};
