export default {
  namespaced: true,

  state: {
    topbar: null,
    isInitialized: false,
  },

  mutations: {
    SET_INITIAL_STATE(state, topbar) {
      state.topbar = topbar;
      state.isInitialized = true;
    },

    SET_MARKETS(state, markets) {
      state.topbar.markets = markets;
    },

    SET_LANGUAGES(state, languages) {
      state.topbar.languages = languages;
    },

    SET_LANGUAGE(state, language) {
      state.topbar.language = language;
    },

    SET_MARKET(state, market) {
      state.topbar.market = market;
    },
  },

  actions: {
    init({ commit }, topbar) {
      commit('SET_INITIAL_STATE', topbar);
    },
  },

  getters: {
    market(state) {
      return state.topbar ? state.topbar.market : null;
    },

    language(state) {
      return state.topbar ? state.topbar.language : null;
    },

    isInitialized(state) {
      return state.isInitialized;
    },
  },
};
