import Vue from 'vue';
import Vuex from 'vuex';

// Import stores
import topbar from './stores/topbar';
import topbarPortal from './stores/topbar.portal';
import topbarMarketWeb from './stores/topbar.market-web';
import shipment from './stores/shipment';

Vue.use(Vuex);

export default new Vuex.Store({

  state: {
    isLoading: false,
    loaderTitle: null,
    loaderDescription: null,
  },

  mutations: {
    SET_IS_LOADING(state, { title = null, description = null }) {
      state.isLoading = true;
      state.loaderTitle = title;
      state.loaderDescription = description;
    },

    SET_DONE_LOADING(state) {
      state.isLoading = false;
      state.loaderTitle = null;
      state.loaderDescription = null;
    },
  },

  getters: {
    isLoading: ((state) => state.isLoading),
    loaderTitle: ((state) => state.loaderTitle),
    loaderDescription: ((state) => state.loaderDescription),
  },

  // Register stores
  modules: {
    topbar,
    topbarPortal,
    topbarMarketWeb,
    shipment,
  },
});
