export default {
  namespaced: true,

  state: {
    topbarState: null,
    supportedMarkets: ['SE', 'DK'],
    supportedLanguagesSE: [
      { key: 'sv', name: 'Svenska' },
      { key: 'en', name: 'English' },
    ],
    supportedLanguagesDK: [
      { key: 'da', name: 'Dansk' },
      { key: 'en', name: 'English' },
    ],
  },

  actions: {
    async init({ state, commit, getters }, topbarName) {
      state.topbarState = topbarName === 'portal' ? 'topbarPortal' : 'topbarMarketWeb';

      const selectedMarket = window.location.href.includes(process.env.VUE_APP_SE_BASE_URL) ? 'SE' : 'DK';
      await commit(`${state.topbarState}/SET_MARKET`, selectedMarket, { root: true });

      const defaultLanguage = getters.language || localStorage.getItem('language') || 'sv';
      const selectedLanguage = getters.availableLanguages.map((item) => item.key).includes(defaultLanguage)
        ? defaultLanguage
        : getters.availableLanguages[0].key;

      commit(`${state.topbarState}/SET_LANGUAGE`, selectedLanguage, { root: true });
      commit(`${state.topbarState}/SET_LANGUAGES`, getters.availableLanguages, { root: true });
      commit(`${state.topbarState}/SET_MARKETS`, state.supportedMarkets, { root: true });
    },

    async setMarket({ state, commit, getters }, market) {
      await commit(`${state.topbarState}/SET_MARKET`, market, { root: true });
      commit(`${state.topbarState}/SET_LANGUAGES`, getters.availableLanguages, { root: true });
    },

    setLanguage({ state, commit }, language) {
      localStorage.setItem('language', language);
      commit(`${state.topbarState}/SET_LANGUAGE`, language, { root: true });
    },

  },

  getters: {
    market(state, getters, rootState, rootGetters) {
      return rootGetters[`${state.topbarState}/market`];
    },

    language(state, getters, rootState, rootGetters) {
      return rootGetters[`${state.topbarState}/language`];
    },

    isInitialized(state, getters, rootState, rootGetters) {
      return rootGetters[`${state.topbarState}/isInitialized`];
    },

    availableLanguages(state, getters) {
      return getters.market === 'SE' ? state.supportedLanguagesSE : state.supportedLanguagesDK;
    },
  },
};
