<script>
/* eslint-disable no-param-reassign,no-shadow,no-underscore-dangle,prefer-destructuring,no-cond-assign,func-names */
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters('topbar', [
      'market',
    ]),
  },
  methods: {
    ...mapActions('topbar', [
      'setMarket',
    ]),
  },
  watch: {
    market(newMarket, oldMarket) {
      const basePath = newMarket === 'DK' ? process.env.VUE_APP_DK_BASE_URL : process.env.VUE_APP_SE_BASE_URL;

      if (!basePath) {
        return;
      }

      const topbarParam = window.location.pathname.split('/')[2];

      if (oldMarket) {
        const url = `${window.location.protocol}//${window.location.host}/${basePath}${topbarParam}/${window.location.hash}${window.location.search}`;
        window.location.replace(url);
      } else if (window.location.href.indexOf(basePath) === -1) {
        this.setMarket(newMarket === 'DK' ? 'SE' : 'DK');
      }

      if (newMarket === 'SE') {
        (function (n, t, i, r, u, f, e) {
          for (let o, s = /[?&]{1}(humany[^=]*)=([^&#]*)/g; o = s.exec(n.location.search);)i += `${(i.indexOf('?') > -1 ? '&' : '?') + o[1]}=${o[2]}`;
          f = t.createElement(u); f.async = !0; f.src = i; e = t.getElementsByTagName(u)[0]; e.parentNode.insertBefore(f, e); n[r] = n[r] || { _c: [], configure(t) { n[r]._c.push(t); } };
        }(window, document, '//postnord.humany.net/postnord-site-floating/embed.js', 'Humany', 'script'));
      }
    },
  },
};
</script>

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss">
  @import '@/styles/main.scss';

  #app {
    overflow-x: hidden;
  }
</style>
