import Vue from 'vue';
import Axios from 'axios';
import VueI18n from 'vue-i18n';
import { defineCustomElements } from '@postnord/web-components/loader';
import { defineCustomElements as defineCustomElementsMarketWeb } from '@postnord/pn-marketweb-components/esm-es5/loader';
import App from './App.vue';
import store from './store';
import router from './router';

import enMessages from './i18n/en.json';
import svMessages from './i18n/se.json';
import daMessages from './i18n/dk.json';

Vue.config.productionTip = false;

defineCustomElements();
defineCustomElementsMarketWeb();

Vue.use(VueI18n);

Vue.config.ignoredElements = [
  /^pn-/,
];

// Append api-key and region to all requests
Axios.interceptors.request.use((config) => {
  const market = store.getters['topbar/market'];
  const areaKey = market === 'SE' ? 'market' : 'region';

  config.params = config.params || {};
  config.params[areaKey] = market;

  return config;
});

const i18nMessages = {
  en: enMessages,
  sv: svMessages,
  da: daMessages,
};

const i18n = new VueI18n({
  locale: 'sv',
  messages: i18nMessages,
});

new Vue({
  VueI18n,
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount('#app');
