import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import { DEFAULT_TOPBAR_PREFIX, TOPBAR_PREFIXES } from './constants';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/:topbar',
      name: 'wrapper',
      component: () => import('./views/Wrapper.vue'),
      props: true,
      beforeEnter: checkTopbarParam,
      children: [
        {
          path: 'payment-redirect',
          name: 'payment-redirect',
          meta: {
            header: 'banners/pay-vat.svg',
            headerColor: '#E0F8FF',
            icon: 'credit-card',
            step: 'payment',
          },
          component: () => import('./views/redirect/redirect.vue'),
        },
        {
          path: 'search',
          name: 'search',
          meta: {
            header: 'banners/pay-vat.svg',
            headerColor: '#E0F8FF',
            icon: 'search',
          },
          component: () => import('./views/search/Search.vue'),
        },

        {
          path: 'shipment',
          name: 'shipment',
          meta: {
            header: 'banners/pay-vat.svg',
            headerColor: '#E0F8FF',
            icon: 'box',
          },
          component: () => import('./views/shipment/Shipment.vue'),
          beforeEnter: initShipment,
        },
        {
          path: 'shipment/returned',
          name: 'returned',
          meta: {
            header: 'banners/pay-vat.svg',
            headerColor: '#E0F8FF',
            icon: 'box',
          },
          component: () => import('./views/shipment/Returned.vue'),
          beforeEnter: initShipment,
        },
        {
          path: 'shipment/annulled',
          name: 'annulled',
          meta: {
            header: 'banners/pay-vat.svg',
            headerColor: '#E0F8FF',
            icon: 'box',
          },
          component: () => import('./views/shipment/Annulled.vue'),
          beforeEnter: initShipment,
        },
        {
          path: 'customs-declaration',
          name: 'customs-declaration',
          meta: {
            header: 'banners/pay-vat.svg',
            headerColor: '#E0F8FF',
            icon: 'clock',
          },
          component: () => import('./views/customs-declaration/CustomsDeclaration.vue'),
          beforeEnter: initShipment,
        },

        {
          path: 'customs-declaration-delayed',
          name: 'customs-declaration-delayed',
          meta: {
            header: 'banners/pay-vat.svg',
            headerColor: '#E0F8FF',
            icon: 'clock',
            step: 'customs-declaration',
          },
          component: () => import('./views/customs-declaration/CustomsDeclarationDelayed.vue'),
          beforeEnter: initShipment,
        },

        {
          path: 'customs-shipment',
          name: 'customs-shipment',
          meta: {
            header: 'banners/pay-vat.svg',
            headerColor: '#E0F8FF',
            icon: 'box',
          },
          component: () => import('./views/customs-shipment/CustomsShipment.vue'),
          beforeEnter: initShipment,
        },

        {
          path: 'accept',
          name: 'accept',
          meta: {
            header: 'banners/pay-vat.svg',
            headerColor: '#E0F8FF',
            icon: 'box',
          },
          component: () => import('./views/accept/Accept.vue'),
          beforeEnter: initShipment,
        },

        {
          path: 'payment',
          name: 'payment',
          meta: {
            header: 'banners/pay-vat.svg',
            headerColor: '#E0F8FF',
            icon: 'credit-card',
          },
          component: () => import('./views/payment/Payment.vue'),
          beforeEnter: initShipment,
        },

        {
          path: 'receipt',
          name: 'receipt',
          meta: {
            header: 'banners/pay-vat.svg',
            headerColor: '#E0F8FF',
            icon: 'invoice',
          },
          component: () => import('./views/receipt/Receipt.vue'),
          beforeEnter: initShipment,
        },

        {
          path: 'invoice',
          name: 'invoice',
          meta: {
            header: 'banners/pay-vat.svg',
            headerColor: '#E0F8FF',
            icon: 'invoice',
            step: 'receipt',
          },
          component: () => import('./views/invoice/Invoice.vue'),
          beforeEnter: initShipment,
        },

        {
          path: 'invoice-paid',
          name: 'invoice-paid',
          meta: {
            header: 'banners/pay-vat.svg',
            headerColor: '#E0F8FF',
            icon: 'invoice',
            step: 'receipt',
          },
          component: () => import('./views/invoice/InvoicePaid.vue'),
          beforeEnter: initShipment,
        },

        {
          path: 'confirmation-prepaid-vat',
          name: 'confirmation-prepaid-vat',
          meta: {
            header: 'banners/pay-vat.svg',
            headerColor: '#E0F8FF',
            icon: 'invoice',
          },
          component: () => import('./views/confirmation/ConfirmationPrepaidVat.vue'),
          beforeEnter: initShipment,
        },
      ],
    },
    {
      path: '/:itemId/:code',
      name: '',
      beforeEnter: redirectToSearch,
    },
  ],

  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// Middleware to load a package before route enter
function initShipment(to, from, next) {
  if (store.getters['shipment/isInitialized']) {
    next();
  } else {
    next({ name: 'search', query: { ...to.query, cb: to.name }, params: { topbar: to.params.topbar } });
  }
}

function redirectToSearch(to, from, next) {
  if (Object.values(TOPBAR_PREFIXES).includes(to.params.itemId)) {
    next({ name: 'search', params: { topbar: to.params.itemId } });
  } else {
    next({ name: 'search', query: to.params, params: { topbar: DEFAULT_TOPBAR_PREFIX } });
  }
}

function checkTopbarParam(to, from, next) {
  if (Object.values(TOPBAR_PREFIXES).includes(to.params.topbar)) {
    if (to.matched.length > 1) {
      next();
    } else {
      next({ name: 'search', params: { topbar: to.params.topbar } });
    }
  } else {
    next({ path: `/${DEFAULT_TOPBAR_PREFIX}/${to.params.topbar}`, query: { ...to.query } });
  }
}

// If the user visits a route with no component, fallback to search
router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    if (to.path.substring(0, 3) === `/${TOPBAR_PREFIXES.PORTAL}/`) {
      next({ name: 'search', params: { topbar: TOPBAR_PREFIXES.PORTAL } });
    } else {
      next({ name: 'search', params: { topbar: TOPBAR_PREFIXES.MARKETWEB } });
    }
  } else {
    next();
  }
});

export default router;
